import ErrorModal from './ErrorModal'
import Meta from './Meta'
import Link from 'next/link'

const MainLayout = ({
	title1,
	title2,
	description,
	errorTitle,
	errorMessage,
	...props
}: {
	title1: string
	title2?: string
	description: string
	errorTitle?: string
	errorMessage?: string
	[x: string]: any
}) => {
	return (
		<>
			<Meta
				title={`${title1} ${title2 ? `| ${title2}` : ''}`}
				description={description}></Meta>

			<div
				id='particleParent'
				className={`flex flex-col min-h-screen overflow-hidden ${
					props.disableBackground ? 'bg-no-stars' : 'bg-star-pattern'
				}`}>
				<div className='flex-wrap justify-center mt-auto'>
					{props.children}
					{props.header}
				</div>

				{/*  Site footer */}
				{!props.disableFooter && (
					<footer className='p-2 sm:p-6 text-xs sm:text-sm footer footer-center text-gray-100 pb-10 mt-auto'>
						<div className='flex flex-wrap justify-center gap-4'>
							<Link className='link link-hover' href='/about'>
								About
							</Link>
							<Link className='link link-hover' href='/contact'>
								Contact
							</Link>
							<Link className='link link-hover' href='/faq'>
								FAQ
							</Link>
							<Link className='link link-hover' href='/legal/terms-and-conditions'>
								Terms and Conditions
							</Link>
							<Link className='link link-hover' href='/legal/privacy-policy'>
								Privacy Policy
							</Link>
							<Link className='link link-hover' href='/legal/cookies'>
								Cookies
							</Link>
						</div>
					</footer>
				)}
			</div>
			<ErrorModal
				title={errorTitle}
				error={errorMessage}
				clicked={props.setErrorMessage}></ErrorModal>
		</>
	)
}

export default MainLayout
