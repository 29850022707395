import AuthLayout from '../shared/components/AuthLayout'
import configData from '../lib/utils/siteconfig'

export default function Home({}) {
	return (
		<AuthLayout
			title1={configData.title}
			title2='404 | Page not found'
			description='Page not found'>
			<h1 className='lg:text-5xl text-3xl font-display mx-auto text-center  text-white font-semibold mt-16'>
				404 | Page not found
			</h1>
		</AuthLayout>
	)
}
