import MainLayout from './MainLayout'
import Logo from './Logo'

const authLayout = ({
	title1,
	title2,
	description,
	errorTitle,
	errorMessage,
	...props
}: {
	title1: string
	title2?: string
	description: string
	errorTitle?: string
	errorMessage?: string
	[x: string]: any
}) => {
	return (
		<MainLayout
			title1={title1}
			title2={title2}
			description={description}
			errorTitle={errorTitle}
			errorMessage={errorMessage}
			{...props}>
			<div className='mt-10 flex flex-col'>
				{!props.disableLogo && <Logo title={'Hide & Seek World'}></Logo>}
				{props.children}
			</div>
		</MainLayout>
	)
}

export default authLayout
